.contact-list {
    display: flex;
    width: 100%;
    /* grid-template-columns: repeat(3, 33%);
    grid-template-rows: repeat(auto-fill, auto);
    grid-row-gap: 1em;
    grid-column-gap: 1em; */
    flex-direction: row;
    flex-wrap: wrap;
}

.contact-method {
    min-width: 30%;
    margin:  2em auto;
    border: 2px solid var(--cm-cl);
    display: grid;
    grid-template-columns: 3em auto;
    grid-template-rows: auto auto;
    /* padding: 1em; */
}

.contact-method:nth-child(4n+0) {--cm-cl: var(--pal-b-em); --cm-bg: var(--pal-b-txbg);}
.contact-method:nth-child(4n+1) {--cm-cl: var(--pal-c-em); --cm-bg: var(--pal-c-txbg);}
.contact-method:nth-child(4n+2) {--cm-cl: var(--pal-d-em); --cm-bg: var(--pal-d-txbg);}
.contact-method:nth-child(4n+3) {--cm-cl: var(--pal-e-em); --cm-bg: var(--pal-e-txbg);}

.contact-method .cm-icon {
    text-align: center;
    grid-area: 1/1/2/2;
    padding: 1em 0.5em 0.5em 1em;
    background-color: var(--cm-cl);
    color: white;
}

.contact-method .cm-text {
    grid-area: 1/2/1/3;
    padding: 1em 1em 0.5em 0.5em;
    background-color: var(--cm-cl);
    color: white;
}

.contact-method .cm-value {
    grid-area: 2/1/3/3;
    padding: 0.5em 1em 1em 1em;
    color: var(--cm-cl);
    font-weight: bold;
}