:root {
  --pal-a: #fff;
  --pal-a-em: #d8d8e0;
  --pal-b: #6bc7a2;
  --pal-b-em: #439d87;
  --pal-b-txbg: #badbde;
  --pal-c: #a3d0d4;
  --pal-c-em: #4da0a8;
  --pal-c-txbg: #a6ddc7;
  --pal-d: #b274cf;
  --pal-d-em: #5e7297;
  --pal-d-txbg: #d5b3e5;
  --pal-e: #8a806e;
  --pal-e-em: #755676;
  --pal-e-txbg: #d2cec6;

  --at-bg: var(--pal-b);
  --at-txt: black;
  --at-txt-em: var(--pal-b-em);
}

.top-level {
  display: flex;
  flex-direction: column;
  height: 100%;
}
