.publication {
    display: grid;
    /* grid-gap: 1em; */
    grid-template-columns: auto auto auto auto;
    grid-template-rows: auto auto auto auto auto auto;
    border: 4px solid var(--pub-em);
    border-radius: 2em;
    padding: 1.25em 1.5em;
}

.publication:nth-child(4n+0) {--pub-em: var(--pal-b-em);}
.publication:nth-child(4n+1) {--pub-em: var(--pal-c-em);}
.publication:nth-child(4n+2) {--pub-em: var(--pal-d-em);}
.publication:nth-child(4n+3) {--pub-em: var(--pal-e-em);}

.pub-title {
    grid-area: 1/1/2/4;
    color: var(--pub-em);
    font-weight: bold;
    font-family: 'Comfortaa', sans-serif;
    border-bottom: 2px solid var(--pub-em);
}

.pub-date {
    grid-area: 1/4/2/5;
    color: var(--pal-e);
    font-weight: bold;
    font-family: 'Comfortaa', sans-serif;
    text-align: right;
}

.conference {
    grid-area: 2/1/3/5;
    font-family: 'Comfortaa', sans-serif;
    font-size: small;
    color: var(--pal-e);
    padding: 0.5em 0 1em 0;
}

.author-list {
    grid-area: 3/1/4/5;
    display: flex;
    flex-direction: row;
    overflow: auto;
    white-space: nowrap;
}

.abstract {
    grid-area: 4/1/5/5;
    padding: 1em 0;
}

a.doi-link {
    grid-area: 6/1/7/4;
    border-bottom: none;
    color: var(--pal-e);
    font-family: 'Comfortaa' sans-serif;
}

a.doi-link:hover {
    color: var(--pub-em);
}

a.full-text-link {
    grid-area: 5/4/7/5;
    text-align: center;
    border: 2px solid var(--pub-em);
    font-family: 'Comfortaa', sans-serif;
    font-weight: bolder;
    padding: 1em;
    border-radius: 1em;
    color: var(--pub-em);
    background-color: white;
    transition: color 0.25s, background-color 0.25s;
}

a.full-text-link:hover {
    color: white;
    background-color: var(--pub-em);
}

.pub-author {
    background-color: var(--auth-bg);
    border: 1px solid var(--auth-cl);
    padding: 0.1em 0.5em;
    margin: 0 0.5em;
    border-radius: 0.5em;
}

.pub-author:nth-child(4n+0) {--auth-cl: var(--pal-b-em); --auth-bg: var(--pal-b-txbg);}
.pub-author:nth-child(4n+1) {--auth-cl: var(--pal-c-em); --auth-bg: var(--pal-c-txbg);}
.pub-author:nth-child(4n+2) {--auth-cl: var(--pal-d-em); --auth-bg: var(--pal-d-txbg);}
.pub-author:nth-child(4n+3) {--auth-cl: var(--pal-e-em); --auth-bg: var(--pal-e-txbg);}